.evp-reveal {
  position: relative;
  overflow: hidden;

  & > * {
    animation: fadeIn 0s 0.6s;
    animation-fill-mode: backwards;
  }

  &_opacity {
    animation: fadeIn 1.5s;
  }

  &::after {
    position: absolute;
    content: '';
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: $dark2;
    transform: translateY(-100%);
    animation: revealIn 0.6s 0s, revealOut 0.6s 0.6s;
    animation-fill-mode: forwards;
  }
  &_primary::after {
    background: $primary;
  }
  &_purple::after {
    background: $purple;
  }
  &_gray::after {
    background: $gray;
  }
  &_ltr::after {
    animation: revealInLTR 0.6s 0s, revealOutLTR 0.6s 0.6s;
  }
  &_rtl::after {
    animation: revealInRTL 0.6s 0s, revealOutRTL 0.6s 0.6s;
  }
  &:nth-of-type(odd) &::after {
    animation: revealInBTT 0.6s 0s, revealOutBTT 0.6s 0.6s;
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes revealIn {
  0% {
    transform: translateY(-100%);
  }
  100% {
    transform: translateY(0);
  }
}
@keyframes revealOut {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(101%);
  }
}
@keyframes revealInLTR {
  0% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(0);
  }
}
@keyframes revealOutLTR {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(101%);
  }
}
@keyframes revealInRTL {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(0);
  }
}
@keyframes revealOutRTL {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-101%);
  }
}
@keyframes revealInTTB {
  0% {
    transform: translateY(-100%);
  }
  100% {
    transform: translateY(0);
  }
}
@keyframes revealOutTTB {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(101%);
  }
}
@keyframes revealInBTT {
  0% {
    transform: translateY(100%);
  }
  100% {
    transform: translateY(0);
  }
}
@keyframes revealOutBTT {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(-101%);
  }
}
