// Only grid
@import "../node_modules/bootstrap/scss/bootstrap-grid";
@import "../node_modules/photoswipe/dist/photoswipe.css";

// Local vars
@import "variables";

// bs basics
@import "../node_modules/bootstrap/scss/mixins/border-radius";
@import "../node_modules/bootstrap/scss/reboot";
@import "root";

// Components

@import "type";
@import "button";
@import "reveal";
@import "header";
@import "experience";
@import "header-links";
@import "header-skills";
@import "hobbies";

/* width */
::-webkit-scrollbar {
  width: .3rem;
}

/* Track */
::-webkit-scrollbar-track {
  background: $bg-darken;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: $dark-gray;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: $gray;
}

.link-primary {
  border-bottom: 2px solid;
  color: $gray;
  line-height: 1rem;
  margin: auto;
  padding: 0 0 2px;
  text-decoration: none;
  text-shadow: 0 0 10px darken($white, 75);
  width: fit-content;

  &:hover, &:focus {
    color: $header-link-hover-color !important;
    text-shadow: 0 0 10px $header-link-hover-color;
  }
}

a:hover, a:focus {
  outline: none;
}
