.evp-profile-links {
  height: 2rem;

  &__int {
    height: 1.7rem;
    overflow: hidden;
    position: relative;
  }

  &__skills {
    bottom: 1px;
    cursor: pointer;
    position: absolute;
    width: 100%;
    white-space: nowrap;

    &_on {
      transform: translateY(0%);
      transition: .2s ease;

      .evp-t-skills & {
        transition: .2s ease;
        transform: translateY(200%);
        visibility: hidden;
      }
    }

    &_off {
      visibility: hidden;
      transform: translateY(-200%);

      .evp-t-skills & {
        transition: .2s ease;
        transform: translateY(0%);
        visibility: visible;
      }
    }
  }

  &__ext {
    $item-height: 2.1rem;
    $item-width: 2.5rem;
    $icon-width: 2.1rem;
    $icon-size: 65%;

    box-shadow: 0 0 8px 2px $dark;
    width: $item-width * 3;

    &-item {
      position: relative;
      width: $item-width;

      i {
        display: block;
        height: $item-height;
        margin: auto;
        transition: all .2s;
        width: $icon-width;
        z-index: 1;
      }

      &:before {
        background: $gray;
        border-radius: 2px;
        bottom: 50%;
        content: '';
        height: 2px;
        position: absolute;
        right: 50%;
        transition: all .2s;
        width: 0;
        z-index: 0;
      }

      &:hover, &:focus {
        &:before {
          background: transparentize($gray, 0.75);
          bottom: 0;
          box-shadow: 0 0 2px 2px $gray;
          height: 100%;
          right: 0;
          width: 100%;
        }
      }

      &_share {
        display: none;
      }

      @each $link in ('github', 'linkedin', 'share') {
        &_#{$link} i {
          background: url('../img/#{$link}.svg') center no-repeat;
          background-size: $icon-size $icon-size;
          position: relative;
        }
      }
    }
  }
}
