@import "player";

$title-height: 4rem;

.evp-t-hobbies {
  .evp-header, .evp-main {
    visibility: hidden;
  }
}

.evp-hobbies {
  background: $dark;
  position: absolute;
  top: 0;
  left: -100%;
  height: 100%;
  width: 100%;
  z-index: 999;
  transform: translateX(-100%);
  transition: all .3s;
  visibility: hidden;

  img {
    tab-index: 0;
  }

  .evp-t-hobbies & {
    left: 0;
    transform: translateX(0%);
    transition: all .3s;
    visibility: visible;
  }

  &__content {
    &-title {
      height: $title-height;
      padding: 1.4rem 0 0;
      text-align: left;
    }
  }

  a#{&}__close {
    cursor: pointer;
    margin: auto 0;
    display: none;

    .evp-t-hobbies & {
      display: block;
    }
  }
}

.evp-gallery {
  overflow-y: scroll;
  max-height: calc(100% - ($title-height + $player-height));

  &__col {
    &_sq {
      height: 300px;
    }
    &_v {
      height: 400px;
    }
  }

  &__item {
    display: block;
    height: auto;
    max-width: 100%;
    position: relative;
    overflow: hidden;

    img {
      height: auto;
      width: 100%;
    }

    &:after {
      position: absolute;
      content: '';
      top: 50%;
      left: 50%;
      height: 100%;
      width: 100%;
      background: rgba($dark, .15);
      transition: all .2s;
      transform: translate(-50%, -50%);
    }

    &:hover, &:focus {
      &:after {
        height: 0;
      }
    }
  }
}
