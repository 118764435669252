$player-height: 5rem;

.evp-player {
  position: absolute;
  bottom: 0;
  left: 0;
  height: $player-height;
  width: 100%;
  background: $dark2;

  $player: &;

  .container,
  .container-fluid {
    height: 100%;
    position: relative;
  }

  svg {
    max-height: 100%;
    color: $gray;

    path {
      fill: $gray;
    }

    &:hover, &:focus, &:active {
      color: $secondary-light;

      path {
        fill: $secondary-light;
      }
    }
  }

  &__title {
    height: auto;
    margin: 0;
    padding: 0;
  }

  &__control {

    .evp-i-pause {
      visibility: hidden;
    }

    @at-root #{$player}_is-playing {
      .evp-i-play {
        transform: translateY(-100%);
        visibility: hidden;
      }
      .evp-i-pause {
        transform: translateY(-100%);
        visibility: visible;
      }
    }

    &-item {
      width: 3rem;
      height: 2rem;
      cursor: pointer;
      padding: 0 .5rem;

      &:hover, &:focus {
        &:before {
          filter: hue-rotate(180deg);
        }
      }
    }
  }

  &__info {
    overflow: hidden;
    max-width: 25%;

    &-title {
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      width: 100%;
    }
  }

  &__embed {
    position: absolute;
    bottom: 0;
    right: 0;
    transition: all .2s;

    @at-root #{$player}_is-expanded & {
      border-top: 5px solid $dark;
      height: 225px;
      transition: all .2s;
      transform: translateX(0);
      width: 400px;
    }
  }

  &__toggle {
    position: absolute;
    right: -3rem;
    top: 1.5rem;
    height: 2rem;
    width: 4rem;
    cursor: pointer;
    justify-self: end;

    .evp-i-minimize {
      visibility: hidden;
    }

    @at-root #{$player}_is-expanded {
      .evp-i-expand {
        transform: translateY(-100%);
        visibility: hidden;
      }
      .evp-i-minimize {
        transform: translateY(-100%);
        visibility: visible;
      }
    }
  }
}
