@font-face {
  font-family: 'nihonium113';
  font-display: swap;
  src: url('../fonts/nihonium113.ttf');
  size-adjust: 140%;
}

h1, h2, h3, h4, h5 {
  color: $heading-color;
  text-align: right;
  white-space: nowrap;
}
