:root {

  // Note: Use `inspect` for lists so that quoted items keep the quotes.
  // See https://github.com/sass/sass/issues/2383#issuecomment-336349172
  --#{$prefix}font-sans-serif: #{inspect($font-family-sans-serif)};
  --#{$prefix}font-monospace: #{inspect($font-family-monospace)};
  --#{$prefix}gradient: #{$gradient};

  // Root and body
  // scss-docs-start root-body-variables
  @if $font-size-root != null {
    --#{$prefix}root-font-size: #{$font-size-root};
  }
  --#{$prefix}body-font-family: #{inspect($font-family-base)};
  @include rfs($font-size-base, --#{$prefix}body-font-size);
  --#{$prefix}body-font-weight: #{$font-weight-base};
  --#{$prefix}body-line-height: #{$line-height-base};
  @if $body-text-align != null {
    --#{$prefix}body-text-align: #{$body-text-align};
  }

  --#{$prefix}body-color: #{$body-color};
  --#{$prefix}body-color-rgb: #{to-rgb($body-color)};
  --#{$prefix}body-bg: #{$body-bg};
  --#{$prefix}body-bg-rgb: #{to-rgb($body-bg)};

  --#{$prefix}emphasis-color: #{$body-emphasis-color};
  --#{$prefix}emphasis-color-rgb: #{to-rgb($body-emphasis-color)};

  --#{$prefix}secondary-color: #{$body-secondary-color};
  --#{$prefix}secondary-color-rgb: #{to-rgb($body-secondary-color)};
  --#{$prefix}secondary-bg: #{$body-secondary-bg};
  --#{$prefix}secondary-bg-rgb: #{to-rgb($body-secondary-bg)};

  --#{$prefix}tertiary-color: #{$body-tertiary-color};
  --#{$prefix}tertiary-color-rgb: #{to-rgb($body-tertiary-color)};
  --#{$prefix}tertiary-bg: #{$body-tertiary-bg};
  --#{$prefix}tertiary-bg-rgb: #{to-rgb($body-tertiary-bg)};
  // scss-docs-end root-body-variables
}
