.evp-header {
  background: $header-bg url('../img/header-bg.png') repeat 10% 25%;
  padding-top: 3rem;
  position: relative;
  z-index: 0;

  &:before {
    content: "";
    background: linear-gradient(rgba(lighten($dark, 10), .1), rgba($dark, .3), rgba($dark, .8));
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: -1;
  }

  &__container {
    width: 100%;
  }
}

.evp-profile {
  background: $dark;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px 2px;
  max-height: $header-profile-height;
  margin: auto;
  position: relative;
  width: 35rem;

  &__locals {
    position: absolute;
    top: -2rem;
    right: 0;

    &-link {
      color: $gray;
      text-transform: uppercase;

      @each $lang in $languages {
        html[lang=#{$lang}] &--#{$lang} {
          color: $primary;
        }
      }
    }

    span {
      font-size: .8rem;
    }
  }

  &__title {
    color: $header-title-color;
    text-align: left;
    width: fit-content;
  }

  &__name {
    background: $header-name-bg;
    color: $header-name-color;
    text-transform: uppercase;
    width: fit-content;
    white-space: nowrap;
  }

  &__info {
    background: $header-desc-bg url('../img/header-info.png') no-repeat;
    color: $header-desc-color;
    font-size: $header-desc-font-size;
    height: 3rem;
    width: 12rem;
  }

  &__avatar {
    $height: 13rem;
    $width: 11rem;

    height: $height;
    position: relative;
    width: $width;

    &:before {
      content: '';
      background: url('../img/avatar.png') no-repeat top center;
      background-size: $height * 0.923;
      height: $height;
      left: 0;
      position: absolute;
      top: -2rem;
      width: $width;
    }
  }
}
