@use "sass:math";

.evp-skills {

  $duration: 60s;
  overflow: hidden;

  &__container {
    flex-direction: row;
    color: $red;
    text-shadow: $dark 0 0 2px;
    position: relative;
    height: 2rem;
    line-height: 0.5rem;
    opacity: 0;
    transition: .5s ease;

    .evp-t-skills & {
      opacity: 1;
      transition: .5s ease;
    }
  }

  &__item {
    &:after {
      content: '●';
      padding: 0 1rem;
    }
  }

  &__first, &__second {
    position: absolute;
    top: 10px;
    right: 0;
    white-space: nowrap;
    transform: translateX(-100%);
    animation: running-line $duration infinite linear forwards;
  }

  &__second {
    animation: $duration running-line math.div($duration, 2) infinite linear forwards;
  }
}

@keyframes running-line {
  0% {
    transform: translateX(100%);
  }
  50% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-100%);
  }
}
