$color: $purple;
$darken-color: darken($color, 4);

.evp-btn {
  background: transparent;
  border: 2px solid $color;
  border-radius: .5rem;
  box-shadow: 0 .25em $bg-darken3;
  color: $color;
  font-size: 1.2rem;
  padding: .5rem;
  position: relative;
  text-decoration: none;
  text-transform: uppercase;
  top: 0;
  transition: all 300ms ease-in-out;

  &:hover, &:focus {
    background: $color;
    color: $bg-darken3;
    outline: none;
  }

  &:active {
    top: .25rem;
  }
}

.evp-btn-icon {
  background: transparent;
  border: none;

  &:hover, &:focus {
    color: $secondary-light;
    outline: none;

    path {
      fill: $secondary-light;
    }
  }
}
