@use "sass:math";

.evp-exp {

  $exp: &;

  $routes-pets: 'pets', 'embed-mixcloud-advanced', 'iframe-new-tab', 'spaze', 'sehack';
  $box-items-transition: (
    smarteka: 'translateX(-100%)',
    socialbet: 'translateX(150%)',
    'iframe-new-tab': 'translateX(-100%)',
    spaze: 'translateX(86%)',
    sehack: 'translateX(14%)',
  );
  $box-items-content-transition: (
    socialbet: (
      left: initial,
      right: 28.5%,
    ),
    ignet: (
      left: initial,
      right: 37%,
    ),
    "embed-mixcloud-advanced": (
      left: 42.5%,
      right: initial,
    ),
    "iframe-new-tab": (
      left: 42.5%,
      right: initial,
    ),
    spaze: (
      left: initial,
      right: 33%,
    ),
    sehack: (
      left: initial,
      right: 33%,
    ),
  );

  height: 100%;

  &__desc {
    text-align: right;
  }

  &_hidden {
    transform: translateY(220%);
    transition: transform .2s;
    visibility: hidden;
  }

  &__grid {
    $grid: &;
    background: $bg-darken;
    border: solid $bg-darken;
    border-width: 1.5rem 0;
    margin-bottom: $exp-nav-height;
    position: relative;
    width: 100%;

    .row {
      width: 110%;
    }

    &_pets {
      transition: all .2s;
      visibility: hidden;
      position: absolute;
      transform: translateY(0%);

      @each $pet-name in $routes-pets {
        .evp-h-#{$pet-name} & {
          transform: translateY(calc(-100% - #{$exp-nav-height}));
          transition: all .2s;
          visibility: visible;
        }

        @at-root .evp-h-#{$pet-name} #{$exp}__grid:not(&) {
          visibility: hidden;
        }
      }
    }
  }

  // COLUMNS //

  &__col {
    transition: transform .2s, visibility .1s;

    @each $name, $transition in $box-items-transition {
      .evp-h-#{$name} &_#{$name} {
        transform: #{$transition};
      }
    }

    &_ignet #{$exp}__box {
      padding-bottom: 64.5%;
    }

    &_spaze, &_sehack {

      #{$exp}__box {
        overflow: visible;

        a {
          box-shadow: none;

          .img-thumbnail {
            opacity: 1;
            width: 120%;
          }
        }
      }
    }

    &_spaze {
      margin-right: -1.5rem;
    }
  }

  // BOX //

  &__box {
    $box: &;
    padding-bottom: 100%;
    position: relative;
    overflow: hidden;
    height: 100%;

    a {
      box-shadow: 0 0 15px darken($primary, 20);
      height: 100%;
      position: absolute;
      width: 100%;

      .img-thumbnail {
        opacity: .75;
        filter: grayscale(100%);
        height: 100%;
        transition: .5s ease;
        width: 100%;
      }

      &:hover, &:focus, {
        .img-thumbnail {
          filter: none;
          opacity: 1;
          transition: .5s ease;
        }

        #{$box}-desc {
          bottom: 0;
        }
      }
    }

    &-desc {
      background-image: $primary-bg-gradient;
      background-size: 200% auto;
      bottom: -2.75rem;
      box-shadow: 0 0 15px $dark;
      color: $white;
      cursor: pointer;
      overflow: hidden;
      left: 0;
      line-height: 1.2rem;
      position: absolute;
      text-overflow: ellipsis;
      transition: .5s ease;
      white-space: nowrap;
      width: 100%;

      span {
        font-size: .75rem;
      }

      &:hover {
        background-position: right center;
        filter: none;
        opacity: 1;
      }
    }

    &-title {
      text-transform: uppercase;
      text-overflow: ellipsis;
      overflow: hidden;
    }

    &-more {
      background: darken($dark, 2);
      width: fit-content;
      margin: auto;
    }

    // BOX hover animations

    $desc: #{&}-desc;

    @each $name in $exp-routes {
      .evp-h-#{$name} & {
        a {
          cursor: default;

          .img-thumbnail {
            filter: none;
            opacity: 1;
          }
        }

        &-desc {
          bottom: -1.55rem;
        }

        &:hover, &:focus {
          #{$desc} {
            bottom: -1.55rem !important;
          }
        }
      }
    }
  }

  // CONTENT //

  &__content {
    color: $white;
    background: $bg-darken;
    visibility: hidden;
    position: absolute;
    left: 28.5%;
    top: 100%;
    height: 100%;
    transition: top .2s;
    text-align: justify;

    a {
      display: block;
    }

    &-thumbnail {
      width: 50%;
      float: left;
      display: none;
      overflow: hidden;
      position: relative;
      padding: .5rem 1rem .5rem 0;

      .img-thumbnail {
        height: 100%;
        width: 100%;
      }
    }

    &-desc {
      display: none;
      background-image: $primary-bg-gradient;

      &-title {
        text-transform: uppercase;
      }
    }

    &-scrollable {
      height: 0;
      &-inner {
        max-height: 100%;
        overflow-y: auto;

        span {
          white-space: break-spaces;
        }
      }
    }

    ///////////////////////
    // Content Transition //
    ///////////////////////

    @each $name in $exp-routes {
      .evp-h-#{$name} & {
        &_#{$name} {
          visibility: visible;
          top: 0;
          transition: top .2s;
        }
      }
    }

    @each $name, $props in $box-items-content-transition {
      .evp-h-#{$name} & {
        &_#{$name} {
          @each $prop, $value in $props {
            #{$prop}: #{$value};
          }
        }
      }
    }
  }

  // NAVIGATION //

  &__nav {
    background: $dark;
    bottom: 0;
    height: $exp-nav-height;
    position: absolute;
    width: 100%;
    z-index: 99;

    a {
      margin: 0 auto;
    }
  }

  &__link {
    &-back {
      opacity: 0;
      visibility: hidden;
      transition: .2s ease;
    }
    &-pets {
      transition: .2s ease;
    }

    @each $route in $routes-pets {
      .evp-h-#{$route} & {
        &-pets {
          opacity: 0;
          visibility: hidden;
          transition: .2s ease;
        }
        &-back {
          opacity: 1;
          visibility: visible;
          transition: .2s ease;
        }
      }
    }
  }
}
